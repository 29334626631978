.page {
    padding: 20px 240px 26px 240px;
    display: flex;
    justify-content: center;
   
    .center {
        .title {
            margin: 20px 0;
            display: flex;
            justify-content: space-between;
        }
        .content {
            height: 1000px;
            width: 1400px;
            background: rgba(255, 255, 255, 1);
            border: 1px solid rgba(151, 151, 151, 0.5);

            .head {
                display: flex;
                div {
                    margin: 20px 40px;
                    font-size: 20px;
                    font-weight: 500;
                    letter-spacing: 0px;
                    color: rgba(50, 50, 50, 1);
                }
            }

            .row {
                margin: 10px 40px;
                display: flex;
                height: 50px;

                .author {
                    width: 200px;
                }

                .reviewers {
                    display: flex;
                    align-items: center;

                    .deleteBtn {
                        width: 25px;
                        height: 25px;
                        position: relative;
                        left: 1px;
                        bottom: 13px;
                        cursor: pointer;
                    }

                    .reviewer {
                        margin: 0 10px;
                        border-radius: 8px;
                        border: 1px solid rgba(151, 151, 151, 0.5);
                        padding: 6px 0px 6px 16px;
                        display: flex;
                        align-items: center;
                    }

                    .addBtn {
                        margin: 0 10px;
                    }
                
                }
            }
        }
    }


}

.modal {
    min-height: 300px;
    .checkbox {
        margin-right: 16px;
    }
}

.bottom {
    text-align: center;
    margin-bottom: 50px;
    .btn {
        width: 160px;
    }
}