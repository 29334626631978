.body {
  margin-top: 0px;
  background-color: #2E7AE5;
  display: flex;
  flex-wrap: nowrap;
  padding: 30px 125px 30px 167px;
  align-items: center;
  justify-content: space-between;
}


.left {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  color: #ffffff;

  .title {
    line-height: 75px;
    font-size: 30px;
    padding: 16px 38px;
    border-left: 1px solid #ffffff;
  }

  .subTitle {
    margin-left: 20px;
    font-size: 20px;
    margin-top: 14px;

  }
}

.leftLogo {
  width: 183px;
  margin-right: 30px;
}

.img {
  width: 61.02px;
  height: 58px;
  margin-right: 18px;
}

.normalTab, .currentTab {
  font-family: PingFangSC-Regular;
  font-size: 26px;
  color: #9B9B9B;
  letter-spacing: 0;
  line-height: 28px;
  font-weight: 400;
  margin: 0 30px 0 30px;
  text-decoration: none;
  cursor: pointer;
}

.firstTab {
  font-family: PingFangSC-Regular;
  font-size: 26px;
  color: #999999;
  letter-spacing: 0;
  line-height: 28px;
  font-weight: bold;
  margin: 0 30px 0 30px;
  text-decoration: none;
  cursor: pointer;
}

.menuDivider {
  height: 40px;
  border: 0.6px solid #D3D3D3;
  margin: 0 10px;
  cursor: default !important;
  
}

.tabTitle {
  font-family: PingFangSC-Regular;
  font-size: 30px;
  letter-spacing: 1px;
  text-decoration: none;
  color: #9B9B9B;
  margin: 0 60px 0 0;
}

.currentTab {
  color: #2E7AE5;
}

.right {
  display: flex;
  flex-wrap: nowrap;
  display: flex;
  height: 28px;
  flex-wrap: nowrap;
  /* margin-left: 300px; */
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 23.44px;
  color: rgba(255, 255, 255, 1);
  margin-top: 20px;
}

.right > div, .left > div {
  cursor: pointer;
}


.button {
  padding: 0 39px;
  border-right: 1px solid #ffffff;
}


.userInfo {
  padding: 0 39px;
}

.pop:global.ant-popover-content {
  background: #FFFFFF;
  border: 1px solid #DEDEDE;
  box-shadow: 0 2px 10px 0 rgba(0,0,0,0.16);
  border-radius: 8px;
  height: 108px;
}

.myAccount, .logOut {
  font-family: PingFangSC-Regular;
  font-size: 20px;
  color: #343434;
  letter-spacing: 0;
  line-height: 60px;
  font-weight: 400;
  width: 180px;
  margin-left: 17px;
  cursor: pointer;
  white-space: nowrap;
}

.divider {
  border: 0.1px solid #D3D3D3;
  width: 186px;
}
