
body {
  background-color: #F3F5F9;
}

.logIn {
  display: flex;
  flex-wrap: nowrap;
}

.left {
  width: 50%;
  padding-left: 224px;
  padding-top: 248px;
}

.leftLogo {
  width: 264px;
}

.coin {
  width: 35px;
  height: 35px;
  margin-left: 5px;
  margin-right: 5px;
  position: relative;
  top: 5px;
}

.loginHint {
  margin-top: 80px;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 21.09px;
  color: rgba(88, 88, 88, 1);
}


.leftContent {
  font-family: PingFangSC-Regular;
  font-size: 28px;
  color: #585858;
  /* width: 1056px; */
  margin-right: 200px;
}

.bold {
    font-family: PingFangSC-Semibold;
}

.right {
  width: 50%;
  flex: 1;
  position: relative;
}

.loginGuide {
  width: 238.77px;
  transform: rotate(1deg);
  position: relative;
  left: 550px;
  top: 100px;
}

.rightBox {
  width: 753px;
  margin-top: 43px;
  padding:103px 58px 119px 58px;
  background: #FFFFFF;
  border: 1px solid #EEEEEE;
  box-shadow: 0 3px 20px 10px rgba(0,0,0,0.09);
  border-radius: 15px;
}

.rightTitle {
  /* font-family: Arial-BoldMT; */
  font-size: 50px;
  font-weight: 400;
  color: rgba(88, 88, 88, 1);
}

.account, .password {
  display: flex;
  flex-flow: row nowrap;
  background: #F5F5F5;
  border: 1px solid #D7D7D7;
  border-radius: 15px;
}

.account {
  margin-top: 88px;
}

.password {
  margin-top: 46px;
}

.accountImg, .pswImg {
  margin: 25px 60px 28px 27px;
}

.accountImg {
  height: 45px;
  width: 48px;
  margin: 25px 60px 28px 27px;
}

.pswImg {
  height: 45px;
  width: 42px;
  margin: 25px 62px 28px 27px;
}

.input {
  width: 484px;
  background-color: #F5F5F5;
  border-style: none;
  font-family: ArialMT;
  font-size: 35px;
  color: #6D7278;
}

input:focus {
  outline: none;
}

.rightTerms {
  margin-top: 36px;
  font-family: ArialMT;
  font-size: 25px;
  color: #6D7278;
  display: flex;
  align-items: center;
}

.termsLink {
  font-family: Arial-BoldMT;
  font-size: 25px;
  color: #0091FF;
  text-decoration: underline;
  margin-left: 5px;
}

.checkbox {
  border-style: none;
  margin-right: 10px;
}

.buttonNormal, .buttonDisable {
  margin: 61px auto 0px auto;
  width: 310px;
  line-height: 98px;
  border-radius: 10px;
  font-family: PingFangSC-Regular;
  font-size: 40px;
  text-align: center;
  cursor: pointer;
  color: #FFFFFF;
}

.buttonNormal {
  background-color: #2E7AE5;
}

.buttonDisable {
  background: #BFD9FF;
}

.link {
  position: absolute;
  top: 1158px;
  right: 357px;
  font-family: Arial-ItalicMT;
  font-size: 30px;
  color: #2E7AE5;
  letter-spacing: 0;
  line-height: 40px;
  /* font-weight: 400; */
  text-decoration: underline;
}

.xxLink {
  font-size: 35px;
  font-family: Arial-ItalicMT;
  font-size: 30px;
  color: #2E7AE5;
  letter-spacing: 0;
}

.underLink {
  text-decoration: underline;
}