.item {
    min-height: 180px;
    border-radius: 8px;
    background: rgba(255, 255, 255, 1);
    border: 1px solid rgba(217, 217, 217, 1);
    margin-bottom: 25px;
    padding: 14px 24px 4px 35px;

    .titleWrapper {
        display: flex;
        justify-content: space-between;


        .title {
            font-size: 22px;
            font-weight: 400;
            letter-spacing: 0px;
            color: rgba(52, 52, 52, 1);
        }

        .editIcon {
            width: 20px;
            height: 20px;
            cursor: pointer;
        }
    }

    .contentWrapper {
        display: flex;
        justify-content: space-between;
        height: 130px;
        overflow: hidden;

        .buttonGroup {
            display:flex;
            flex-direction: column;

            .button {
                margin-top: 14px;
                font-size: 12px;
            }
        }
        .content {
            margin-top: 10px;
            font-size: 15px;
            font-weight: 400;
            letter-spacing: 0px;
            color: rgba(52, 52, 52, 1);

            & > div {
                overflow: hidden;
                text-overflow: ellipsis;
                /* max-height: 60px; */
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                word-wrap: break-word;
                word-break: break-all;
            }
        }


    }
}