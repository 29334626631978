.input {
    height: 64px;
    background: rgba(243, 245, 249, 1)!important;
}

.wrapper {
    display: flex;
    white-space: nowrap;
    align-items: center;
    margin-bottom: 12px;
}

.label {
    color: rgba(49, 49, 49, 1);
    font-size: 22px;
    margin-right: 30px;
    font-weight: 500;
}