.page {
    padding: 36px;
}

.title {
    margin-bottom: 24px;
}

.ideas, .comments, .evaluations {
    margin-top: 48px;
}