.wrapper {
    height: 100%;
}

.contentWrapper {
    padding: 35px 35px 16px 22px; 
    overflow-y: scroll;
    height: 100%;
    max-height: 600px;
    display: flex;
    flex-direction: column;
}

.submitted {
    max-height: calc(100vh - 430px);
}

.title {
    display: flex;
    justify-content: center;
    height: 63px;
    border-radius: 8px 8px, 0px, 0px;
    background: rgba(243, 245, 249, 1);
    color: rgba(109, 139, 201, 1);
    align-items: center;
    border-bottom:  1px solid rgba(89, 124, 194, 1);;
}

.answerWrapper {
    display: flex;
    margin-bottom: 10px;
}

.eva {
    width: 55px;
    height: 55px;
    margin-top: 4px;
}

.name {
    font-size: 10px;
    color: rgba(61, 61, 61, 1);
    margin-left: 25px;
}

.content {
    border-radius: 8px;
    border: 1px solid rgba(211, 211, 211, 1);
    padding: 15px;
    font-size: 18px;
    line-height: 25px;
    color: rgba(61, 61, 61, 1);
    white-space: pre-wrap;
    margin-left: 20px;
    position: relative;
    bottom: 8px;
}

.arrow {
    width: 10px;
    height: 10px;
    border-top: 1px solid rgba(211, 211, 211, 1);
    border-right: 1px solid rgba(211, 211, 211, 1);
    position: relative;
    top: 20px;
    left: 14px;
    z-index: 100;
    background-color: #ffffff;
    transform: rotate(225deg);
}

.questionArrow {
    width: 7px;
    position: relative;
    left: 22px;
}

.bottom {
    /* position: fixed;
    bottom: 0; */
    display: flex;
}

.question {
    border-radius: 8px;
    background: rgba(99, 190, 106, 1);
    padding: 15px;
    font-size: 18px;
    align-self: flex-end;
    color: #ffffff;
    line-height: 25px;
}

.btn {
    cursor: pointer;
    flex: 1;
    padding: 8px 0;
    margin: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 65px;
    color: rgba(46, 122, 229, 1);
    border-radius: 8px;
    background: rgba(243, 245, 249, 1);
    border: 1px solid rgba(46, 122, 229, 1);
}