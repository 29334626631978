.body {
  padding: 50px 166px;

  .content {
    display: flex;
    justify-content: space-between;

    .left, .right {
      .icon {
        width: 32px;
        height: 32px;
        margin-right: 20px
      }

      .title {
        font-size: 30px;
        font-weight: 400;
        letter-spacing: 0px;
        color: rgba(52, 52, 52, 1);
        display: flex;
        align-items: center;
        margin-bottom: 36px;
      }
    }

    .hint {
      width: 1000px;
      color: rgba(52, 52, 52, 1);
      font-size: 24px;
      font-weight: 400;
    }
  }
}


