.item {
    width: 1000px;
    border-radius: 8px;
    background: rgba(255, 255, 255, 1);
    border: 1px solid rgba(217, 217, 217, 1);
    margin-bottom: 28px;

    .titleWrapper {
        display: flex;
        justify-content: space-between;
        font-size: 15px;
        font-weight: 400;
        letter-spacing: 0px;
        color: rgba(52, 52, 52, 1);
        border-bottom: 1px solid rgba(197, 197, 197, 0.5);
        height: 53px;
        align-items: center;
        padding: 0 22px;

        .title {
            overflow: hidden;
            text-overflow: ellipsis;
            max-height: 60px;

            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            word-wrap: break-word;
            word-break: break-all;
        }

        .score {
            display: flex;
            font-size: xx-small;
            white-space: nowrap;
            
            .aiScore, .ranking {
                margin-left: 14px;
            }
            .ranking {
                padding-left: 10px;
                border-left: 1px solid rgba(52, 52, 52, 1);
            }
        }
    }

    .contentWrapper {
        height: 198px;
        display: flex;
        align-items: center;
        padding-left: 30px;
        overflow-x: scroll;
        .dots {
            width: 57px;
            margin: 0 20px;
        }

        .hint {
            text-align: center;
            width: 100%;
            font-size: 20px;
            font-weight: 400;
            letter-spacing: 0px;
            color: rgba(0, 0, 0, 0.25);
        }

        .subTask {
            flex-shrink: 0;
            padding: 22px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 281px;
            height: 104px;
            margin: 0 12px;
            border-radius: 8px;
            background: rgba(255, 255, 255, 1);
            border: 1px solid rgba(214, 214, 214, 1);

            .title {
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                word-wrap: break-word;
                word-break: break-all;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }

        .btn {
            display: flex;
            justify-content: center;
            margin-top: 15px;
        }

    }
}

.green {
    color: #4EB145 !important;
    border-color: #4EB145 !important;
}