.body {
    display: flex;
    background-color: #ffffff;

    .bg {
        position: absolute;
        width: 900px;
        right: -100px;
        bottom: 0;
        z-index: 1;
        // overflow: hidden;
    }

    .left {
        padding: 29px 50px 0 72px;
        background-color: #F3F5F9;
        // min-height: 100vh;
        border-right: 1px solid rgba(163, 190, 228, 1);
    }

    .right {
        width: 100%;
        // min-height: 100%;
        min-height: calc(100vh - 208px);
        max-height: 1200px;

        background-color: #ffffff;
        overflow-y: scroll;
        
        padding: 0 70px 40px 70px;

        .titleTop {
            color: rgba(59, 59, 59, 1);
            font-size: 30px;
            margin-top: 20px;
            margin-bottom: 20px;
            display: flex;
            justify-content: space-between;

            .titleLeft {
                display: flex;
            }
            .titleRight {
                .score {
                    color: rgba(46, 122, 229, 1);
                }
            }
            
        }

        .commentTi {
            z-index: 100;
            position: relative;
            color: rgba(0, 0, 0, 0.85);
            font-weight: 500;
            font-size: 26px;
            margin-top: 30px;
            margin-bottom: 20px;
        }
    }

    .segTitle {
        font-size: 30px;
        font-weight: 500;
        margin-top: 20px;
        margin-bottom: 50px;
        width: 1000px;
    }



    .seg {
        border-radius: 8px;
        border: 1px solid rgba(163, 190, 228, 1);
        padding: 36px 50px 30px 50px;
        margin-bottom: 28px;
        background-color: #ffffff;
        width: 1000px;

        .title {
            margin-top: 12px;
        }

        .contentTitle {
            padding-bottom: 30px;
            border-bottom: 1px solid rgba(184, 190, 197, 1);
        }

        .content {
            padding: 35px 6px;
            height: 840px;
            white-space: pre-wrap;
            overflow-y: scroll;
            line-height: 1.5;
        }
    }

    .rightSeg {
        width: 1100px;
    }

    // .segTop {
    //     max-height: 300px;
    //     overflow-y: scroll;
    //     padding-bottom: 30px;
    // }

    .draftTextArea {
        height: 740px;
        resize: none;
    }

    .revisionTextArea {
        height: 800px;
        resize: none;
    }

    .cntWord {
        position: relative;
        text-align: right;
        top: 66px;
        color: rgba(43, 43, 43, 1)
    }


    .btnWrapper {
        text-align: right;
        margin-bottom: 34px;
    }

    .close {
        color: rgba(89, 124, 194, 1);
        float: right;
        width: 20px;
        height: 20px;
        position: relative;
        right: 6px;
        top: 6px;
        cursor: pointer;
    }



    .rightContent {
        max-height: calc(100vh - 300px);
        display: flex;
        flex-direction: column;
        // overflow: hidden;
    }

    .ai {
        width: 700px;
        margin-left: 28px;
        border-radius: 8px;
        border: 1px solid rgba(89, 124, 194, 0.7);
        height: 100%;
        // overflow-y: scroll;
    }

    .hints {
        margin-left: 28px;
        display: flex;
        flex-direction: column;
        max-width: 700px;
        align-items: center;
        justify-content: center;

        .hint {
            padding: 20px 50px;
            margin-bottom: 20px;
        }
    }

    // .btns {
    //     margin-top: 20px;
    //     // margin-bottom: 12px;
    //     display: flex;
    //     justify-content: center;
    //     .btn {
    //         width: 240px;
    //         margin-right: 16px;
    //         display: flex;
    //         justify-content: center;

    //         .iconDiv {
    //             margin-left: 18px;
    //             // display: inline-block;
    //             flex-shrink: 0;
    //             width: 44px;
    //             height: 44px;
    //             border-radius: 28px;
    //             background-color: #ffffff;
    //             display: flex;
    //             justify-content: center;
    //             align-items: center;

    //             .icon {
    //                 color: rgba(46, 122, 229, 1);
    //                 width: 18px;
    //             }
    //         }
    //     }
    // }

    .btnGroup {
        margin-top: 60px;
        margin-bottom: 30px;
        padding-left: 200px;
        display: flex;
        align-items: center;

        .btn {
            width: 240px;
            margin-left: 90px;
        }

        .text {
            margin-left: 20px;
            font-size: xx-small;
            color: rgba(114, 114, 114, 1);
        }
    }

    .btnDownload {
        display: flex;
        justify-content: center;
        .btn {
            padding: 0 30px;
        }
    }
    
}



.hint {
    padding: 32px 13px 0 29px;
    color: rgba(61, 61, 61, 1);

    .title {
        font-size: 30px;
        font-weight: 500;
        
    }

    .subTitle {
        font-size: 24px;
        margin-top: 20px;
    }

    .def {
        margin-bottom: 20px;
        font-size: xx-small;
    }

    .hintContent {
        font-size: xx-small;
    }
}

.operation {
    width: 100%;
    // padding: 10px 14px 10px 24px;

    .div {
        margin: 10px 14px 10px 24px;
        border: 1px solid rgba(211, 211, 211, 1);
        
        display: flex;
    }

    .opLeft {
        flex: 1;
        border-right: 1px solid rgba(211, 211, 211, 1);
        padding: 11px 23px 27px 19px;
        

        .title {
            font-size: 26px;
            font-weight: 600;
            margin-bottom: 32px;
            margin-top: 14px;
        }

        .scoreWrapper {
            display: flex;
            margin-bottom: 6px;
            align-items: center;

            .name {
                font-size: 22px;
                min-width: 160px;
            }
        }

        .commentTitle {
            margin-top: 34px;
            font-size: 22px;
            // margin-bottom: 24px;
        }

        .textarea {
            border-radius: 7px;
            background: rgba(243, 245, 249, 1);
            resize: none;
        }

        .btnWrapper {
            display: flex;
            justify-content: flex-end;
            margin-top: 20px;
        }

    }
    .opRight {
        flex: 1;
    }

    
}

.aiHints {
    margin-left: 28px;
    display: flex;
    flex-direction: column;
    // max-width: 700px;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    margin-bottom: 30px;

    .aiScore {
        color: #2E7AE5;
    }
}

.head,.tableRow {

    .first {
        min-width: 260px;
        flex-grow: 0;
        flex-shrink: 0;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 58px;


    & > div {
        flex: 1;
    }
}

.head {
    font-size: middle;
    font-weight: 500;
}

.aiHelp {
    display: flex;
    margin-top: 50px;
}

.btns {
    .btn {
        margin-right: 10px;
    }
}

.green {
    color: #4EB145 !important;
    border-color: #4EB145 !important;
}

.orange {
    color: #F3622D !important;
    border-color: #F3622D !important;
}

.finalHint {
    margin: 50px 0;
    justify-content: center;
    display: flex;
    font-size: 28px;
    color: rgba(0, 0, 0, 0.5);
}