.group {
    display: flex;
   
    .score {
        width: 28px;
        height: 28px;
        margin-right: 6px;
        background-color: #ffffff;
        color: rgba(61, 61, 61, 1);
        border: 1px solid rgba(186, 199, 218, 1);
        display: flex;
        align-items: center;
        font-size: 18px;
        justify-content: center;
        cursor: pointer;
    }

    .active {
        background-color: rgba(46, 122, 229, 1);
        color: #ffffff;
        border: 1px solid rgba(46, 122, 229, 1);
    }
}

.icon {
    margin-right: 8px;
    width: 31px;
    height: 31px;
    cursor: pointer;
}