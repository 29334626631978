.page {
    text-align: center;
    padding: 36px;
}

.pagination {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
  }
  .top {
    margin-top: 20px;
    padding: 0 200px;
    display: flex;
  }

  .filter {
    display: flex;
    margin-right: 40px;
    align-items: center;
    .selector {
        width: 310px;
        margin-left: 20px;
    }

    .taskSelector {
        width: 800px;
        margin-left: 20px;
    }


  }

  .table {
    margin-top: 40px;
    padding: 0 100px;
  }



