.body {
    /* height: 1183px; */
    display: flex;
    flex-wrap: nowrap;
    background: #FFFFFF;
    border: 0.1px solid #979797;
    border-radius: 8px;
    position: relative;
    flex-direction: column;
  }
  
  .close {
    width: 32px;
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }
  
  .left {
    width: 825px;
    background: #FFF6E5;
    padding: 149px 0 0 86px;
    border-radius: 36px 0 0 36px;
  }
  
  .hasDetail, .noDetail {
    margin-bottom: 50px;
  }
  
  .noDetail {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }
  
  .detail {
    display: flex;
    align-items: center;
  }
  
  .title {
    align-self: center;
    display: flex;
    margin-top: 40px;
    margin-bottom: 57px;
    .leaderboard {
      width: 500px;
    }
  }

  .rank {
    display: flex;
    align-items: center;
    flex-direction: column;

    .rankTitle {
      font-size: 24px;
      font-weight: 600;
      letter-spacing: 0px;
      line-height: 23.44px;
      color: rgba(52, 52, 52, 1);
    }
  }

  .rankContent {
    border: 0.5px solid rgba(151, 151, 151, 1);
    width: 500px;
  }

  .content {
    /* height: 500px; */
    margin-bottom: 60px;
    height: 800px;
    overflow: scroll;
    display: flex;
    justify-content: space-evenly;
  }

  .rankItem {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    height: 55px;
    padding: 0 34px;
    justify-content: space-between;

    .rankImg {
      width: 17px;
      /* margin-left: 32px; */
      margin-left: 20px;
    }

    .rankIndex {
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 0px;
      line-height: 18.75px;
      color: rgba(52, 52, 52, 1);
      margin-left: 20px;
    }
  
    .rankName {
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 0px;
      line-height: 18.75px;
      color: rgba(52, 52, 52, 1);
    }

    .rankScore {
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 0px;
      line-height: 18.75px;
      color: rgba(52, 52, 52, 1);
      margin-right: 80px;
    }
  }

  .avatar {
      height: 70px;
      width: 70px;
      border-radius: 35px;
      margin: 0 25px 0 25px;
      border-radius: 100%;
  }
  
  

  .rightContent {
    margin-top: 30px;
  }



  .rankHead {
    background: rgba(53, 137, 255, 0.67);
    display: flex;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 18.75px;
    color: rgba(255, 255, 255, 1);
    height: 57px; 
    align-items: center;
    padding: 0 34px;
    justify-content: space-between;
  }

  .hint {
    font-size: 10px;
    font-weight: 400;
    letter-spacing: 0px;
    color: rgba(52, 52, 52, 1);
    width: 500px;
    margin-top: 16px;
  }