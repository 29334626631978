.page {
    padding: 20px 240px 66px 240px;
}

.title {
    font-family: PingFangSC-Semibold;
    font-size: 50px;
    color: #3F3F3F;
    letter-spacing: 0;
    font-weight: 600;
}

.selectGroup {
    margin: 70px 0 50px 0;
    display: flex;
    align-items: center;
}

.button {
    margin-left: 36px;
    width: 105px;
}

.head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    .title {
        font-size: 32px;
    }

    
}

.selectTitle {
    font-family: PingFangSC-Regular;
    font-size: 30px;
    color: #3F3F3F;
    letter-spacing: 0;
    font-weight: 400;
    margin-right: 25px;
}

.selector {
    width: 310px;
  }

