.card {
    /* height: 516px; */
    width: 100%;
    border-radius: 8px;
    background: rgba(255, 255, 255, 1);
    border: 1px solid rgba(211, 211, 211, 1);
    margin-top: 12px;
    position: relative;



    .tag {
        width: 162px;
        /* margin-top: 14px; */
        position: absolute;
        left: -1px;
        top: 14px;
    }

    .title {
        font-size: 16px;
        color: #fff;
        position: absolute;
        left: 20px;
        top: 22px;
    }

    .content {
        font-size: small;
        margin-top: 60px;
        padding: 18px;
        color: rgba(61, 61, 61, 1);

        .scores {
            display: flex;
            & > div {
                margin-right: 30px;
            }
        }

        .commentTitle {
            margin-top: 12px;
        }

        .comment {
            margin-bottom: 20px;
            padding-bottom: 20px;
            margin-top: 4px;
            line-height: 1.4;
            border-bottom: 1px solid rgba(151, 151, 151, 1);
        }

        .revision {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .helpful {
                font-size: xx-small;
            }
        }

        .rspTitle {
            font-size: 24px;
            font-weight: 500;
        }

        .rsp {
            font-size: 24px;
            font-weight: 500;
            margin-top: 20px;
            margin-bottom: 12px;
        }

        .txt {
            background: rgba(243, 245, 249, 1);
            resize: none;
            
        }
    }
}