

.contentWrapper {
    margin: 16px 60px 0 60px;

    .body {
        padding: 29px 35px;
        background-color: #ffffff;
        display: flex;

        .content {
            border-radius: 8px;
            border: 1px solid rgba(89, 124, 194, 0.7);    
            padding: 64px;    
            flex: 1;
            height: calc(100vh - 440px);
            overflow-y: scroll;

            .seg {
                border-radius: 8px;
                border: 1px solid rgba(163, 190, 228, 1);
                padding: 30px 17px 10px 30px;
                margin-bottom: 28px;

            }

            .draftTextArea {
                height: 740px;
                resize: none;
            }

            .submissionTextArea {
                height: 700px;
                resize: none;
            }

            .cntWord {
                position: relative;
                top: 20px;
                color: rgba(43, 43, 43, 1)
            }


            .btnWrapper {
                text-align: right;
                margin-bottom: 34px;
            }

            .close {
                color: rgba(89, 124, 194, 1);
                float: right;
                width: 20px;
                height: 20px;
                position: relative;
                right: 6px;
                top: 6px;
                cursor: pointer;
            }
        }

        .rightContent {
            max-height: calc(100vh - 300px);
            display: flex;
            flex-direction: column;
            // overflow: hidden;
        }

        .ai {
            width: 700px;
            margin-left: 28px;
            border-radius: 8px;
            border: 1px solid rgba(89, 124, 194, 0.7);
            height: 100%;
            // overflow-y: scroll;
        }

        .hints {
            margin-left: 28px;
            display: flex;
            flex-direction: column;
            max-width: 700px;
            align-items: center;
            justify-content: center;
            font-size: 26px;

            .hint {
                padding: 20px 50px;
                margin-bottom: 20px;
                text-align: center;
            }

            .aiScore {
                color: #2E7AE5;
            }
        }

        .btns {
            margin-top: 20px;
            // margin-bottom: 12px;
            display: flex;
            justify-content: center;
            .btn {
                width: 240px;
                margin-right: 16px;
                display: flex;
                justify-content: center;

                .iconDiv {
                    margin-left: 18px;
                    // display: inline-block;
                    flex-shrink: 0;
                    width: 40px;
                    height: 40px;
                    border-radius: 28px;
                    background-color: #ffffff;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .icon {
                        color: rgba(46, 122, 229, 1);
                        width: 18px;
                    }
                }
            }
        }
        
    }



}
