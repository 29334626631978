.item {
    width: 1000px;
    border-radius: 8px;
    background: rgba(255, 255, 255, 1);
    border: 1px solid rgba(217, 217, 217, 1);
    margin-bottom: 28px;

    .titleWrapper {
        display: flex;
        justify-content: space-between;
        font-size: 15px;
        font-weight: 400;
        letter-spacing: 0px;
        color: rgba(52, 52, 52, 1);
        border-bottom: 1px solid rgba(197, 197, 197, 0.5);
        height: 53px;
        align-items: center;
        padding: 0 22px;



        .score {
            display: flex;
            font-size: xx-small;
            .aiScore, .ranking {
                margin-left: 14px;
            }
            .ranking {
                padding-left: 10px;
                border-left: 1px solid rgba(52, 52, 52, 1);
            }
        }

        .title {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 400px;
        }
    }

    .contentWrapper {
        height: 198px;
        display: flex;
        align-items: center;
        justify-content: center;
        .dots {
            width: 57px;
            margin: 0 20px;
        }

        .waiting {
            font-size: 13px;
            font-weight: 400;
            letter-spacing: 0px;
            color: rgba(152, 152, 152, 1);
            margin: 0 20px;
        }

        .round {
            width: 148px;
            height: 148px;
            border-radius: 128px;
            background-color: rgba(46, 122, 229, 1);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: 0 10px;
            cursor: pointer;

            .title {
                font-size: 20px;
                font-weight: 400;
                letter-spacing: 0px;
                color: rgba(255, 255, 255, 1);
               
            }

            .btn {
                margin-top: 12px;
                height: 21px;
                border-radius: 10.5px;
                background: rgba(255, 255, 255, 1);
                color: rgba(46, 122, 229, 1);
                font-size: 14px;
                padding: 1px 9px;
                display: flex;
                align-items: center;
                .arrow {
                    width: 6px;
                    margin-left: 7px;
                }
            }
        }

        .disabled {
            background-color: rgba(191, 217, 255, 1);
            cursor: not-allowed;
        }
    }
}