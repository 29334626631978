.page {
    padding: 20px 240px 26px 240px;

    display: flex;
    justify-content: space-between;

    .title {
        margin: 30px 0 20px 0;
    }

    .left, .right {
        width: 1000px;
    }

    .contentWrapper {
        padding: 10px 25px 25px 25px;
        width: 950px;
        height: auto;
        border-radius: 8px;
        background: rgba(255, 255, 255, 1);
        border: 1px solid rgba(151, 151, 151, 0.5);
        
        .state {
            margin: 25px 0 17px 0;
        }

        .textarea {
            background-color: #F3F5F9;
        }
    }

    .table {
        height: 1034px;
        opacity: 1;
        border-radius: 8px;
        background: rgba(255, 255, 255, 1);

        border: 1px solid rgba(151, 151, 151, 0.5);

        .head {
            display: flex;
            height: 70px;
            font-size: 25px;
            justify-content: space-evenly;
            border-bottom: 1px solid rgba(151, 151, 151, 0.5);
            div {
                line-height: 70px;
            }
        }
        .row {
            display: flex;
            // justify-content: space-evenly;
            font-size: 25px;
            height: 60px;
            align-items: center;

            .name {
                width: 200px;
                margin-left: 160px;
            }
            .radio {
                width: 120px;
                margin-left: 120px;
            }
        }
    }

}


.bottom {
    text-align: right;
    margin-right: 240px;
    margin-bottom: 50px;
    .btn {
        width: 160px;
    }

    .nextBtn {
        width: 160px;
        margin-right: 20px;
    }
}