.group {
   display: flex;
}

.icon {
    width: 50px;
    height: 50px;
    margin-right: 25px;
}

.step {
    margin-right: 140px;
    width: 675px;
    text-align: center;
    border-radius: 8px 8px 0 0;
    background: rgba(233, 240, 248, 1); 
    height: 80px;
    line-height: 80px;
    color: rgba(166, 196, 238, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.active {
    color: rgba(46, 122, 229, 1);
    background-color: #ffffff;
}

.title {
    margin-right: 50px;
    font-size: 30px;
    font-weight: 400;
}